import { Paper } from "@material-ui/core";
import { ActionButton } from "components/Button";
import { Table } from "react-bootstrap";
import { ErpApi, ErrorHandler } from "system/ApiService";
import { IsDefaultAdmin, IsManagerUser } from "system/Helper";
import { useLoadingDispatch } from "system/LoadingContext";
import { useUserState } from "system/UserContext";

export default function ProfileDetail() {
  const LoadingDispatch = useLoadingDispatch();
  const user = useUserState();

  const erpupdate = () => {
    if (
      window.confirm(
        "ERP 데이터를 실시간 연동하는 작업으로, 기존 데이터를 삭제하고 새로 불러오는 과정입니다. \n해당 작업 동안 사이트 내 모든 데이터를 볼 수 없으며, 반드시 해당 작업이 완료될 때까지 기다려야 합니다. \nERP 업데이트 성공 이후 데이터가 보여지지 않는다면 ERP 배치작업과 중복되어 데이터가 안 보이는 현상이므로 다시 한번 버튼을 클릭하여 업데이트를 진행해 주세요."
      )
    )
      LoadingDispatch({ type: "LOADING" });
    ErpApi.CallData()
      .then((res) => {
        alert("ERP 갱신 완료");
        console.log(res, "erp data update 성공");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert("데이터 업데이트 실패" + msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  if (user) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Paper style={{ maxWidth: "550px", padding: "20px" }} variant="outlined">
          {IsDefaultAdmin(user.id) && <ActionButton onClick={erpupdate}>ERP Update</ActionButton>}
          <Table>
            <tbody>
              {IsManagerUser(user.role) ? (
                <>
                  <tr>
                    <th style={{ width: "200px" }}>ID</th>
                    <td>{user.id}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{user.showName}</td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <th style={{ width: "200px" }}>ID</th>
                    <td>{user.contactPersonId}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{user.contactName}</td>
                  </tr>
                  <tr>
                    <th>Company</th>
                    <td>
                      {user.contactFor && user.contactFor.includes("(") && user.contactFor.includes(")")
                        ? user.contactFor.split("(")[1].split(")")[0]
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th>E-mail</th>
                    <td>{user.contactEmail}</td>
                  </tr>
                  <tr>
                    <th>Tel.</th>
                    <td>{user.contactPhone}</td>
                  </tr>
                  <tr>
                    <th>Currency</th>
                    <td>{user.currencyCode}</td>
                  </tr>
                </>
              )}
              <tr>
                <th>User Category</th>
                <td>{user.role}</td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      </div>
    );
  } else {
    return <></>;
  }
}
